// 最外层盒子样式
%out-box {
  // width: 19.2rem;
  position: relative;
}

// 1920设计稿，所有的值除以100；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.header-index-bg {
  @extend %out-box;

  .header-index-top {
    @extend %inside-box;
    height: (110rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/header/header_bg.png")
      no-repeat;
    background-size: 100% 100%;

    &-left {
      width: (420rem / 100);
      height: (79rem / 100);
      float: left;
      margin-top: (15rem / 100);
    }

    &-right {
      // display: flex;
      float: right;
      margin-top: (33rem / 100);

      &-search {
        width: (360rem/100);
        height: (44rem/100);
        background: url("https://image.bookgo.com.cn/webculture/jm/search-border.png")
          no-repeat;
        background-size: 100% 100%;
        padding: 0 (20rem/100);
        padding-right: 0;
        &-left-input {
          float: left;
          height: (44rem / 100);
          background: none;
          font-size: (16rem / 100);
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          color: #000000;
          line-height: (44rem / 100);
        }

        &-left-input::placeholder {
          color: rgba($color: #000000, $alpha: 0.4);
        }

        &-right-icon {
          float: left;
          width: (24rem / 100);
          height: (24rem / 100);
          margin-top: (9rem / 100);
          vertical-align: middle;
          margin-right: 0.1rem;
        }
        &-right-btn {
          float: right;
          width: 0.71rem;
          height: 0.44rem;
          background: url("https://image.bookgo.com.cn/webculture/jm/search-btn-bg.png")
            no-repeat;
          background-size: 100% 100%;
          line-height: 0.44rem;
          font-size: 0.16rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
          padding-left: 0.16rem;
        }
      }

      &-login {
        width: (80rem / 100);
        height: (36rem / 100);
        background: #8492c6;
        border-radius: (18rem / 100);
        text-align: center;
        line-height: (36rem / 100);
        margin-left: (25rem / 100);
        font-size: (16rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #ffffff;
      }
    }
    &-rightBtn {
      float: right;
      width: 1.1rem;
      height: 0.44rem;
      line-height: 0.44rem;
      background: url("https://image.bookgo.com.cn/webculture/jm/hudong-Btnbg.png")
        no-repeat;
      background-size: 100% 100%;
      margin-top: 0.33rem;
      margin-left: 0.2rem;
      text-align: center;
      font-size: 0.16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.header-index-bottom-bg {
  @extend %out-box;
  background: #761f1e;
  height: (72rem / 100);

  .header-index-bottom {
    // @extend %inside-box;
    height: 100%;

    &-nav {
      width: 100%;
      height: 100%;
      font-size: (16rem / 100);
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      color: #ffffff;
      display: flex;
      justify-content: center;

      &-a {
        color: #ffffff;
        float: left;
        width: (100rem / 100);
        height: 100%;
        vertical-align: middle;
        position: relative;
        z-index: 3;
        height: (72rem / 100);
        text-align: center;

        &-detail {
          width: 100%;
          position: relative;
          float: left;
          height: 100%;
          line-height: (72rem / 100);
          z-index: 3;
          cursor: pointer;
        }
        .slideDown {
          position: absolute;
          width: 1.24rem;
          // height: 1.6rem;
          background-color: rgba($color: #cd9e56, $alpha: 0.9);
          top: 0.72rem;
          left: 0;
          z-index: 1;
          padding-top: 0.28rem;
          &::after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            bottom: -0.4rem;
            left: 0;
            border-top: 0.2rem solid;
            border-left: 0.5rem solid;
            border-right: 0.5rem solid;
            border-bottom: 0.2rem solid;
            border-color: rgba($color: #cd9e56, $alpha: 0.9) transparent
              transparent;
          }
          ul li {
            padding: 0.08rem 0;
            font-size: 0.16rem;
            font-family: PingFangSC-Medium, PingFang SC;
            text-align: center;
            font-weight: 500;
            color: #ffffff;
            line-height: 0.16rem;
            border-bottom: 1px dashed #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            .slideDown-item {
              width: 0.73rem;
              font-size: 0.14rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.22rem;
            }
            &:last-child {
              border-bottom: none;
            }
            img {
              width: 0.06rem;
              height: 0.12rem;
              position: absolute;
              left: 0.04rem;
            }
          }
        }

        .active-nav-detail {
          font-weight: 500;
        }

        .index-nav-detail-active-live {
          width: (100rem / 100);
          height: (100rem / 100);
          position: absolute;
          top: 0;
          left: 0;
          background: url("https://image.bookgo.com.cn/webculture/jm/header/header-nav-selected.png")
            no-repeat;
          background-size: 100% 100%;
          z-index: 2;
        }
      }

      &-a:last-of-type {
        margin-right: 0;
      }
    }
  }
}
