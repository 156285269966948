// 最外层盒子样式
%out-box {
  // width: 19.2rem;
  position: relative;
}

// 1920设计稿，所有的值除以100；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.footer-index-bg {
  @extend %out-box;
  height: (250rem / 100);
  background-color: #761f1e;
  .footer-index {
    @extend %inside-box;
    height: 100%;
    position: relative;
    padding-top: (35rem / 100);

    &-top {
      width: 100%;

      &-link {
        float: left;
        margin-right: (90rem / 100);

        &-top {
          margin-bottom: (27rem / 100);
        }

        &-top-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          margin-right: (10rem / 100);
          float: left;
        }

        &-top-title {
          line-height: (24rem / 100);
          font-size: (18rem / 100);
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
        }

        &-ul {
          padding-left: (34rem / 100);

          &-li {
            width: (331rem / 100);
            margin-bottom: (15rem / 100);

            .footer-index-top-link-ul-li-link {
              padding-right: (5rem / 100);
              height: (16rem / 100);
              border-right: (1rem / 100) solid #ffffff;
              margin-right: (5rem / 100);

              a {
                display: block;
                height: (16rem / 100);
                float: left;
                font-size: (15rem / 100);
                line-height: (16rem / 100);
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                color: #ffffff;
                height: (16rem / 100);
                margin-bottom: (15rem / 100);
              }
            }

            .footer-index-top-link-ul-li-link:nth-of-type(2n) {
              border-right: 0;
            }

            div {
              float: left;
              font-size: (15rem / 100);
              line-height: (16rem / 100);
              font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
              color: #ffffff;
              height: (16rem / 100);
              margin-bottom: (15rem / 100);
            }
          }
        }
      }

      &-contact {
        float: left;
        margin-right: (175rem / 100);

        &-top {
          margin-bottom: (27rem / 100);
        }

        &-top-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          margin-right: (10rem / 100);
          float: left;
        }

        &-top-title {
          line-height: (24rem / 100);
          font-size: (18rem / 100);
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
        }

        &-ul {
          padding-left: (34rem / 100);

          &-li {
            width: (289rem / 100);
            margin-bottom: (15rem / 100);
            font-size: (15rem / 100);
            line-height: (16rem / 100);
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            color: #ffffff;
          }

          .footer-index-top-contact-ul-li-line {
            line-height: (24rem / 100);
          }
        }
      }

      &-attention {
        float: left;

        &-top {
          margin-bottom: (27rem / 100);
        }

        &-top-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          margin-right: (10rem / 100);
          float: left;
        }

        &-top-title {
          line-height: (24rem / 100);
          font-size: (18rem / 100);
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
        }

        &-ul {
          padding-left: (34rem / 100);

          &-li {
            margin-bottom: (15rem / 100);
          }
        }
      }

      &-statistics {
        float: left;

        &-top {
          margin-bottom: (27rem / 100);
        }

        &-top-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          margin-right: (10rem / 100);
          float: left;
        }

        &-top-title {
          line-height: (24rem / 100);
          font-size: (18rem / 100);
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
        }

        &-ul {
          padding-left: (34rem / 100);

          &-li {
            margin-bottom: (15rem / 100);
            font-size: (15rem / 100);
            line-height: (16rem / 100);
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            color: #ffffff;
          }
        }
      }
    }

    &-bottom {
      position: absolute;
      bottom: (30rem / 100);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: (14rem / 100);
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #ffffff;
      line-height: (24rem / 100);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .divLine{
      width: 0.01rem;
      height: 0.15rem;
      background-color: #fff;
      margin-left: 0.1rem;
      margin-right: 0.1rem;
    }
    .jmqyg{
      margin-right: 0.1rem;
    }
  }
}
