* {
  padding: 0px;
  margin: 0px;
  outline: 0 none;
  font-family: "NotoSansHans-Regular", "Microsoft YaHei", "微软雅黑", Georgia,
    Serif;
  /* font-family: 'PingFangSC-Semibold', 'PingFang SC','微软雅黑',Georgia, Serif; */
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body {
  font-size: 12px;
  color: #333;
  background: #ffffff;
  font-family: "NotoSansHans-Regular", "Microsoft YaHei", "微软雅黑", Georgia,
    Serif;
  -webkit-text-size-adjust: 100%;
}

ul,
ol,
li,
i,
em,
cite,
u,
b,
h1,
h2,
h3,
h4,
h5,
h6 {
  list-style: none;
  font-style: normal;
  text-decoration: none;
  font-weight: normal;
}

a,
img {
  border: 0 none;
  text-decoration: none;
  outline: 0 none;
  display: block;
}

a,
a:hover,
a:link,
a:visited,
a:active {
  color: #333;
  -moz-transition: all 0.1s;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  cursor: pointer;
  background: transparent;
}

table,
tr,
td,
th,
thead,
tfoot,
tbody {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input,
select,
textarea,
form {
  outline: 0 none;
  background-color: #fff;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0;
}

p {
  word-wrap: break-word;
}

.tianstion {
  transition: all 0.1s;
  -moz-transition: all 0.1;
  -ms-transition: all 0.1;
  -o-transition: all 0.1s;
  -webkit-transition: all 0.3s;
}

.clear {
  clear: both;
  overflow: hidden;
  *zoom: 1;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

/* 手机屏幕 */
/* @media screen and(max-width: 767px) {
    body {
        font-size: 10px;
        width: 100%;
    }
} */

/*>=1024的设备屏幕*/
/* @media screen and(min-width: 1024px) {

    html,
    body {
        font-size: 12px;
        width: 100%;
    }
} */

/*>=1100的设备屏幕*/
/* @media screen and(min-width: 1100px) {

    html,
    body {
        font-size: 14px;
        width: 100%;
    }
} */

/*>=1280的设备屏幕*/
/* @media (min-width: 1280px) {

    html,
    body {
        font-size: 18px;
        width: 100%;
    }
} */

/*>=1366的设备屏幕*/
/* @media screen and(min-width: 1366px) {

    html,
    body {
        font-size: 20px;
        width: 100%;
    }
} */

/*>=1440的设备屏幕*/
/* @media screen and(min-width: 1440px) {
    body {
        font-size: 24px !important;
    }
} */

/*>=1680的设备屏幕*/
/* @media screen and(min-width: 1680px) {
    body {
        font-size: 26px;
    }
} */

/*>=1920的设备屏幕*/
/* @media screen and(min-width: 1920px) {
    body {
        font-size: 30px;
    }
} */
html {
  width: 100%;
  min-height: 100vh;
  font-size: calc(100vw / 19.2);
  background: #ffffff;
}
.wh_container[data-v-2ebcbc83] {
  max-width: unset !important;
}
